// import Inferno from 'inferno';
// import Component from 'inferno-component';
import React from 'react';

import styles from './ProfileStep.css';
import openStepActionStream from '../streams/openStepActionStream';
import dataStream from '../streams/dataStream';
import orderStream from '../streams/orderStream';
import {Profile} from '../types/data';
import selectProfileActionStream from '../streams/selectProfileActionStream';
import Button from './Button';
import ButtonGroup from './ButtonGroup';
import link from '../utils/link';
import getOrderItemPrice from '../utils/getOrderItemPrice';
import Stream from '../libs/Stream';
import I18n from '../libs/I18n';
import languageStream from '../streams/languageStream';
import getConvertedPrice from '../utils/getConvertedPrice';
import config from '../config';
import _ from "lodash";

const {VAT_MODIFIER} = config;

let i18n = new I18n();

export interface ProfileDetailProps {
	profile: Profile;
	isSelected: boolean;
	handleClick: (profile: Profile) => void;
}

export class ProfileDetail extends React.Component<ProfileDetailProps, {}> {
	render() {
		let order = orderStream.value;
		let orderItem;

		if (order.currentItem !== null) {
			orderItem = order.items[order.currentItem];
		}

		if (!this.props) {
			return;
		}

		let {windowPrice: currentWindowPrice} = getOrderItemPrice(orderItem);
		let {windowPrice: nextWindowPrice} = getOrderItemPrice(
			{...orderItem,
				profile: {
					id: this.props.profile.id,
					name: this.props.profile.name,
					priceModifier: this.props.profile.priceModifier,
					rebate: this.props.profile.rebate
				}}
		);
		let delta = nextWindowPrice && currentWindowPrice ? getConvertedPrice((nextWindowPrice - currentWindowPrice) * VAT_MODIFIER[languageStream.value.current]) : 0;

		return (
			<div className={styles.profileDetail + (this.props.isSelected ? ` ${styles.isSelected}` : '')} onClick={this.handleClick}>
				<h4 className={styles.profileDetailHeading}>{this.props.profile.name[languageStream.value.current]}</h4>

				<figure className={styles.profileDetailPhoto}>
					<img src={link('assets', this.props.profile.image)} alt={this.props.profile.name[languageStream.value.current]} />
				</figure>

				<p className={styles.profileDetailTitle}>{this.props.profile.title[languageStream.value.current]}</p>
				<p className={styles.profileDetailDescription}>
					{this.props.profile.description1[languageStream.value.current]}
				</p>
				<p className={styles.profileDetailDescription}>
					{this.props.profile.description2[languageStream.value.current]}
				</p>
				<p className={styles.profileDetailPriceComparison}>
					{delta >= 0 ?
						i18n.translate `ProfileStep - profile detail - positive price - ${delta}:c` :
						i18n.translate `ProfileStep - profile detail - negative price - ${delta * -1}:c`}
				</p>
			</div>
		);
	}

	handleClick = (event) => {
		if (this.props) {
			this.props.handleClick(this.props.profile);
		}
	};
}

export interface ProfileStepProps {
	isOpen: boolean;
}

export default class ProfileStep extends React.Component<ProfileStepProps, {}> {
	onLanguageStream: Stream<{}>;

	componentDidMount() {
		this.onLanguageStream = languageStream.on(() => {
			// console.log('ProfileStep 1');
			// requestAnimationFrame(() => {
			//	 console.log('ProfileStep 2');
			this.forceUpdate();
			// });
		});
	}

	componentWillUnmount() {
		this.onLanguageStream.end.push(true);
	}

	render() {
		let {profiles, windowTypes} = dataStream.value;
		
		let orderItem = orderStream.value.currentItem === null ? undefined : orderStream.value.items[orderStream.value.currentItem];
		if (!orderItem) {
			return null;
		}
		
		let windowType = orderItem && orderItem.window ? _.find(windowTypes, {id: orderItem.window.typeId}) : undefined;
		
		// we can change profile only if window type has no predefined profile
		let filteredProfiles = [];
		if (!(windowType && _.isFinite(windowType.profileId))) {
			filteredProfiles = profiles.filter(
				(profile) => orderItem && orderItem.material && profile.materialIds.includes(orderItem.material.id)
			);
		} else {
			filteredProfiles = profiles.filter(
				(profile) => orderItem && orderItem.material && profile.materialIds.includes(orderItem.material.id) && profile.id === windowType.profileId
			);
		}
		// console.log('filteredProfiles', filteredProfiles);

		return (
			<section className={styles.root + (this.props && this.props.isOpen ? ` ${styles.isOpen}` : '')}>
				<div className={styles.wrapper}>
					<h1 className={styles.heading}>{i18n.translate `ProfileStep - heading 1`}</h1>
					<span className={styles.closeButton}>
						<Button type="invisible" size="large" icon="cross" handleClick={this.handleCloseClick} />
					</span>

					<div className={styles.profile}>
						<ul className={styles.profilesList}>
							{filteredProfiles.map((profile, index) => <li key={index}>
								<ProfileDetail
									profile={profile}
									isSelected={orderItem && orderItem.profile && profile.id === orderItem.profile.id}
									handleClick={this.handleSelectProfile}
								/>
							</li>
							)}
						</ul>

						<p className={styles.warning}>
							{orderItem.material && orderItem.material.id === 1 && i18n.translate `ProfileStep - warning - material 1`}
							{orderItem.material && orderItem.material.id === 2 && i18n.translate `ProfileStep - warning - material 2`}
						</p>

						<ButtonGroup align="center">
							<Button
								type="flat"
								size="large"
								label={i18n.translate `ProfileStep - button 1`}
								isDisabled={!(orderItem && orderItem.profile)}
								handleClick={this.handleCloseClick}
							/>
						</ButtonGroup>
					</div>
				</div>
			</section>
		);
	}

	handleCloseClick = () => {
		openStepActionStream.push(null);
	};

	handleSelectProfile = (profile) => {
		selectProfileActionStream.push({
			id: profile.id,
			name: profile.name,
			priceModifier: profile.priceModifier,
			rebate: profile.rebate
		});
	};
}
