/* eslint-disable quote-props */

import constants from '../../internals/constants';

const {CS_CZ} = constants;
const {SK_SK} = constants;
const {DE_DE} = constants;
const {HU_HU} = constants;

let i18nStrings: any = {
	'Html - title': {
		[CS_CZ]: 'Kalkulátor oken – okna na míru | OKNA – HNED',
		[SK_SK]: 'Kalkulátor okien - okná na mieru | OKNÁ – HNEĎ',
		[DE_DE]: 'Fensterkonfigurator | FENSTER - JETZT',
		[HU_HU]: 'Ablak árkalkulátor - méretre gyártott ablakok | ABLAKOK - MOST'
	},
	'Html - description': {
		[CS_CZ]: 'Vytvořte si vlastní okna na míru v jednoduchém kalkulátoru. Vaše vlastní plastová okna můžete mít na pár kliknutí.',
		[SK_SK]: 'Vytvorte si vlastné okná na mieru v jednoduchom kalkulátora. Vaše vlastné plastové okná môžete mať na pár kliknutí.',
		[DE_DE]: 'Wir bieten Ihnen einen übersichtlichen Online-Konfigurator der Kunststofffenster an, mit dem Sie selbst Ihr Kunststofffenster nach Maß in die Produktion einplanen.',
		[HU_HU]: 'Szerkessze meg ablakait az átlátható kalkulátorunk segítségével. A méretre gyártandó nyílászárók árajánlatát pár kattintás után megtekintheti.'
	},
	'windowType - variant': {
		[CS_CZ]: 'Otevírání',
		[SK_SK]: 'Otváranie',
		[DE_DE]: 'Öffnungsart',
		[HU_HU]: 'Nyitásirány'
	},
	'windowType - variant - left': {
		[CS_CZ]: 'levé',
		[SK_SK]: 'ľavé',
		[DE_DE]: 'links',
		[HU_HU]: 'balos'
	},
	'windowType - variant - right': {
		[CS_CZ]: 'pravé',
		[SK_SK]: 'pravé',
		[DE_DE]: 'rechts',
		[HU_HU]: 'jobbos'
	},
	'color - one side': {
		[CS_CZ]: 'venkovní',
		[SK_SK]: 'vonkajšie',
		[DE_DE]: 'Dekorfarbe nur außen',
		[HU_HU]: 'külső dekor'
	},
	'color - two sides': {
		[CS_CZ]: 'oboustranná',
		[SK_SK]: 'obojstranná',
		[DE_DE]: 'Dekorfarbe außen und innen',
		[HU_HU]: 'kétoldali dekor'
	},
	'Header - logo - claim': {
		[CS_CZ]: 'Kalkulátor – vytvořte si sami okna přesně podle svých představ!',
		[SK_SK]: 'Kalkulátor - vytvorte si sami okná presne podľa svojich predstáv!',
		[DE_DE]: 'Konfigurator - konfigurieren Sie Ihre Wunschfenster!',
		[HU_HU]: 'Kalkulátor - szerkessze meg nyílászáróit a saját elképzelési szerint!'
	},
	'Header - window - configuring': {
		[CS_CZ]: 'nyní kalkulujete:',
		[SK_SK]: 'teraz kalkulujete:',
		[DE_DE]: 'jetzt konfigurieren:',
		[HU_HU]: 'szerkesztés:'
	},
	'Header - window - {0}': {
		[CS_CZ]: 'Okno #{0}',
		[SK_SK]: 'Okno #{0}',
		[DE_DE]: 'Fenster #{0}',
		[HU_HU]: 'Ablak #{0}'
	},
	'Header - price - actual': {
		[CS_CZ]: 'Cena:',
		[SK_SK]: 'Cena',
		[DE_DE]: 'Preis',
		[HU_HU]: 'Ár:'
	},
	'Header - price - {0}': {
		[CS_CZ]: '{0}',
		[SK_SK]: '{0}',
		[DE_DE]: '{0}',
		[HU_HU]: '{0}'
	},
	'Header - price - no VAT': {
		[CS_CZ]: 'cena bez DPH:',
		[SK_SK]: 'cena bez DPH:',
		[DE_DE]: 'Preis ohne Mwst.',
		[HU_HU]: 'nettó ár (ÁFA nélkül):'
	},
	'Header - price - VAT': {
		[CS_CZ]: 'cena včetně DPH:',
		[SK_SK]: 'cena vrátane DPH:',
		[DE_DE]: 'Preis MwSt. Inklusive',
		[HU_HU]: 'bruttó ár (27%-os ÁFÁ-val):'
	},
	'Header - videotutorial': {
		[CS_CZ]: 'Videonávod',
		[SK_SK]: 'Videonávod',
		[DE_DE]: 'Videoanleitung',
		[HU_HU]: 'Útmutató videó'
	},
	'Footer - links - about us': {
		[CS_CZ]: 'O nás',
		[SK_SK]: 'O nás',
		[DE_DE]: 'Über uns',
		[HU_HU]: 'Rólunk'
	},
	'Footer - links - terms': {
		[CS_CZ]: 'Obchodní podmínky',
		[SK_SK]: 'Obchodné podmienky',
		[DE_DE]: 'Handelsbedingungen',
		[HU_HU]: 'Általános szerződési feltételek'
	},
	'Footer - links - delivery': {
		[CS_CZ]: 'Doba dodání',
		[SK_SK]: 'Doba dodania',
		[DE_DE]: 'Lieferzeit',
		[HU_HU]: 'Szállítási idő'
	},
	'Footer - links - stored': {
		[CS_CZ]: 'Okna skladem',
		[SK_SK]: 'Okná skladom',
		[DE_DE]: 'Lagerfenster',
		[HU_HU]: 'Raktárról elérhető ablakok'
	},
	'StepsList - window type - heading': {
		[CS_CZ]: 'Typ a rozměr okna',
		[SK_SK]: 'Typ a rozmer okna',
		[DE_DE]: 'Fensteryp und Fensterabmessung',
		[HU_HU]: 'Típus és méret'
	},
	'StepsList - window type - button': {
		[CS_CZ]: 'Vybrat typ a rozměr okna',
		[SK_SK]: 'Vybrať typ a rozmer okna',
		[DE_DE]: 'Fensteryp und Fensterabmessung auswählen',
		[HU_HU]: 'A típus és méret kiválasztása'
	},
	'StepsList - material - heading': {
		[CS_CZ]: 'Materiál',
		[SK_SK]: 'Materiál',
		[DE_DE]: 'Material',
		[HU_HU]: 'A keret anyaga'
	},
	'StepsList - material - button': {
		[CS_CZ]: 'Vybrat materiál',
		[SK_SK]: 'Vybrať materiál',
		[DE_DE]: 'Material auswählen',
		[HU_HU]: 'A keret anyagának kiválasztása'
	},
	'StepsList - profile - heading': {
		[CS_CZ]: 'Profil',
		[SK_SK]: 'Profil',
		[DE_DE]: 'Profil',
		[HU_HU]: 'Profilrendszer'
	},
	'StepsList - profile - button': {
		[CS_CZ]: 'Vybrat profil',
		[SK_SK]: 'Vybrať profil',
		[DE_DE]: 'Profil auswählen',
		[HU_HU]: 'A profilrendszer kiválasztása'
	},
	'StepsList - glass - heading': {
		[CS_CZ]: 'Sklo / výplň',
		[SK_SK]: 'Sklo / PVC panel',
		[DE_DE]: 'Verglasung / Füllungen',
		[HU_HU]: 'Üvegezés / PVC panel'
	},
	'StepsList - glass - button': {
		[CS_CZ]: 'Vybrat sklo',
		[SK_SK]: 'Vybrať sklo',
		[DE_DE]: 'Glas auswählen',
		[HU_HU]: 'Az üvegezés kiválasztása'
	},
	'StepsList - color - heading': {
		[CS_CZ]: 'Barva a dekor',
		[SK_SK]: 'Farba a dekor',
		[DE_DE]: 'Farbe und Dekor',
		[HU_HU]: 'Szín és dekor'
	},
	'StepsList - color - button': {
		[CS_CZ]: 'Vybrat barvu a dekor',
		[SK_SK]: 'Vybrať farbu a dekor',
		[DE_DE]: 'Farbe und Dekor auswählen',
		[HU_HU]: 'A szín és dekor kiválasztása'
	},
	'StepsList - accessories - heading': {
		[CS_CZ]: 'Doplňky',
		[SK_SK]: 'Doplnky',
		[DE_DE]: 'Zubehör',
		[HU_HU]: 'Kiegészítők'
	},
	'StepsList - accessories - button': {
		[CS_CZ]: 'Vybrat doplňky',
		[SK_SK]: 'Vybrať doplnky',
		[DE_DE]: 'Zubehör auswählen',
		[HU_HU]: 'A kiegészítők kiválasztása'
	},
	'StepsList - windows - heading': {
		[CS_CZ]: 'Moje okna',
		[SK_SK]: 'Moje okná',
		[DE_DE]: 'Meine Fenster',
		[HU_HU]: 'Nyílászáróim'
	},
	'StepsList - windows - button': {
		[CS_CZ]: 'Přidat další okno',
		[SK_SK]: 'Pridať ďalšie okno',
		[DE_DE]: 'Fenster zufügen',
		[HU_HU]: 'További nyílászáró hozzáadása'
	},
	'StepsList - windows - button overlay': {
		[CS_CZ]: 'Přidejte do objednávky okno s jinými parametry',
		[SK_SK]: 'Pridajte do objednávky okno s inými parametrami',
		[DE_DE]: 'Fenster mit anderen Param',
		[HU_HU]: 'Más tulajdonságokkal rendelkező ablak hozzáadása'
	},
	'StepsList - order - heading': {
		[CS_CZ]: 'Objednávka',
		[SK_SK]: 'Objednávka',
		[DE_DE]: 'Bestellung',
		[HU_HU]: 'Megrendelés'
	},
	'StepsList - order - button': {
		[CS_CZ]: 'Přehled objednávky',
		[SK_SK]: 'Prehľad objednávky',
		[DE_DE]: 'Bestellungsübersicht',
		[HU_HU]: 'Áttekintés'
	},
	'StepsList - order - info': {
		[CS_CZ]: 'Pokud je vaše kalkulace dokončena, můžete přejít ke kontrole a vytvořit objednávku.',
		[SK_SK]: 'Pokiaľ je vaša kalkulácia dokončená, môžete prejsť ku kontrole a vytvoriť objednávku.',
		[DE_DE]: 'Falls Ihre Bestellung gefertig ist, gehen Sie zur Bestellungsübersicht und platzieren Sie die Bestellung.',
		[HU_HU]: 'Amennyiben befejezte a nyílászárók szerkesztését, továbbléphet a kosárba'
	},
	'Main - intro - heading': {
		[CS_CZ]: 'Kalkulátor oken',
		[SK_SK]: 'Kalkulátor okien',
		[DE_DE]: 'Fensterkonfigurator',
		[HU_HU]: 'Ablak árkalkulátor'
	},
	'Main - intro - button start': {
		[CS_CZ]: 'Začít s kalkulací',
		[SK_SK]: 'Začať s kalkuláciou',
		[DE_DE]: 'Jetzt konfigurieren',
		[HU_HU]: 'Szerkesztés'
	},
	'Main - intro - button videotutorial': {
		[CS_CZ]: 'Spustit videonávod',
		[SK_SK]: 'Spustiť videonávod',
		[DE_DE]: 'Videoanleitung starten',
		[HU_HU]: 'Videós útmutató'
	},
	'Main - visualisation - interior view': {
		[CS_CZ]: 'Vnitřní pohled',
		[SK_SK]: 'Vnútorný pohľad',
		[DE_DE]: 'Innenansicht',
		[HU_HU]: 'Belső nézet'
	},
	'Main - visualisation - exterior view': {
		[CS_CZ]: 'Vnější pohled',
		[SK_SK]: 'Vonkajší pohľad',
		[DE_DE]: 'Außenansicht',
		[HU_HU]: 'Külső nézet'
	},
	'Main - intro - text 1': {
		[CS_CZ]: 'Všechny objednávky před zadáním do výroby naši technici kontrolují a telefonicky ověřují správnost objednaných oken a dveří!',
		[SK_SK]: 'Všetky objednávky pred zadaním do výroby naši technici kontrolujú a telefonicky overujú správnosť objednaných okien a dverí!',
		[DE_DE]: 'Alle Bestellungen werden von unseren Technikern geprüft und telefonisch verifiziert, bevor sie in die Produktion gehen!',
		[HU_HU]: 'Mielőtt a nyílászáró a gyártásba kerülne, a technikusaink minden megrendelést átellenőriznek és az ügyféllel telefonon egyeztetnek!'
	},
	'Main - intro - text 2': {
		[CS_CZ]: 'Nebojte se vyzkoušet přehledný online kalkulátor, pomocí kterého si sami vytvoříte objednávku oken a dveří na míru!',
		[SK_SK]: 'Nebojte sa vyskúšať náš jednoduchý online kalkulátor, pomocou ktorého si sami vytvoríte objednávku okien a dverí na mieru!',
		[DE_DE]: 'Scheuen Sie sich nicht, unseren einfach zu bedienenden Online-Kalkulator auszuprobieren, um Ihre individuelle Fenster- und Türenbestellung zu erstellen!',
		[HU_HU]: 'Bátran próbálja ki az egyszerű és könnyen kezelhető online kalkulátorunkat, mely segítségével megszerkesztheti méretre szabott ablakait és ajtóit!'
	},
	'WindowStep - window detail - price - {0}': {
		[CS_CZ]: '{0}',
		[SK_SK]: '{0}',
		[DE_DE]: '{0}',
		[HU_HU]: '{0}'
	},
	'WindowStep - window detail - recommended': {
		[CS_CZ]: 'Doporučené',
		[SK_SK]: 'Odporúčané',
		[DE_DE]: 'Wir empfehlen',
		[HU_HU]: 'Ajánljuk'
	},
	'WindowStep - heading 1': {
		[CS_CZ]: 'Rozměry okna nebo dveří',
		[SK_SK]: 'Rozmery okna alebo dverí',
		[DE_DE]: 'Fenster- oder Türabmessung',
		[HU_HU]: 'A nyílászáró méretei'
	},
	'WindowStep - heading 2': {
		[CS_CZ]: 'Vyberte materiál',
		[SK_SK]: 'Vyberte materiál',
		[DE_DE]: 'Wählen Sie ein Material aus',
		[HU_HU]: 'Válassza ki a nyílászáró keretének anyagát'
	},
	'WindowStep - heading 3': {
		[CS_CZ]: 'Pro zadané rozměry jsou dostupné tyto typy oken',
		[SK_SK]: 'Pre zadané rozmery sú dostupné tieto typy okien',
		[DE_DE]: 'Für ausgewählte Größen sind diese Fenstertypen verfügbar',
		[HU_HU]: 'A megadott méretekhez a következő ablaktípusok állnak rendelkezésre'
	},
	'WindowStep - width': {
		[CS_CZ]: 'Šířka',
		[SK_SK]: 'Šírka',
		[DE_DE]: 'Breite',
		[HU_HU]: 'Szélesség'
	},
	'WindowStep - height': {
		[CS_CZ]: 'Výška',
		[SK_SK]: 'Výška',
		[DE_DE]: 'Höhe',
		[HU_HU]: 'Magasság'
	},
	'WindowStep - button 1': {
		[CS_CZ]: 'Uložit rozměry',
		[SK_SK]: 'Uložiť rozmery',
		[DE_DE]: 'Größen speichern',
		[HU_HU]: 'A méretek mentése'
	},
	'WindowStep - button 1 disabled overlay - {0} {1} {2} {3}': {
		[CS_CZ]: 'Máme okna o velikostech {0} – {1} × {2} – {3} mm',
		[SK_SK]: 'Máme okná o veľkostiach {0} – {1} × {2} – {3} mm',
		[DE_DE]: 'Wir bieten Fenster mit Größen {0} – {1} × {2} – {3} mm',
		[HU_HU]: 'Legyártható méretek: {0} – {1} × {2} – {3}mm '
	},
	'WindowStep - button 1 - no available': {
		[CS_CZ]: 'V tuto chvíli nejsou pro zadaný rozměr dostupné žádné typy okna.\nPro individuální objednávku nás můžete kontaktovat telefonicky nebo e-mailem.',
		[SK_SK]: 'V tejto chvíli nie sú pre zadaný rozmer dostupné žiadne typy okna.\nPre individuálnu objednávku nás môžete kontaktovať telefonicky alebo e-mailom.',
		[DE_DE]: 'Derzeit sind keine Fenstertypen für die angegebene Größe verfügbar.\nFür eine individuelle Bestellung können Sie uns telefonisch oder per E-Mail kontaktieren.',
		[HU_HU]: 'Jelenleg a megadott mérethez nem állnak rendelkezésre választható nyílászáró típusok.\n Amennyiben atipikus nyílászáróra van szüksége, kérjük, lépjen velünk kapcsolatba telefonon vagy e-mailben.'
	},
	'WindowStep - button 2': {
		[CS_CZ]: 'Změnit rozměry',
		[SK_SK]: 'Zmeniť rozmery',
		[DE_DE]: 'Größen ändern / aktualisieren',
		[HU_HU]: 'A méretek módosítása'
	},
	'WindowStep - button 3': {
		[CS_CZ]: 'Potvrdit výběr materiálu',
		[SK_SK]: 'Potvrdiť výber materiálu',
		[DE_DE]: 'Materialauswahl bestätigen',
		[HU_HU]: 'A keret anyagának kiválasztása'
	},
	'WindowStep - button 4': {
		[CS_CZ]: 'Změnit materiál',
		[SK_SK]: 'Zmeniť materiál',
		[DE_DE]: 'Material wechseln',
		[HU_HU]: 'A keret anyagának módosítása'
	},
	'WindowStep - button 5': {
		[CS_CZ]: 'Potvrdit výběr typu okna a pokračovat',
		[SK_SK]: 'Potvrdiť výber typu okna a pokračovať',
		[DE_DE]: 'Auswahl des Fenstertyps bestätigen und weiterleiten',
		[HU_HU]: 'Megerősítés és folytatás'
	},
	'WindowStep - window not available in color': {
		[CS_CZ]: 'Výběrem těchto dveří bude barva nastavena na bílou',
		[SK_SK]: 'Výberom týchto dverí bude farba nastavená na bielu',
		[DE_DE]: 'Wenn Sie diese Tür auswählen, wird die Farbe auf Weiß gesetzt',
		[HU_HU]: 'Ha ezt az ajtót választja, a színt fehérre állítja.'
	},
	'WindowStep - warning': {
		[CS_CZ]: 'Výška okna je uvedena bez 30mm podkladního profilu, který je součástí každého okna a lze jej jednoduše demontovat.',
		[SK_SK]: 'Výška okna je uvedená bez 30mm podkladového profilu, ktorý je súčasťou každého okna a možno ho jednoducho demontovať.',
		[DE_DE]: 'Fensterhöhe ist ohne 30 mm Grundprofil angegeben, das Profil ist ein Teil jedes Fensters und kann leicht demontiert werden.',
		[HU_HU]: 'Az ablak magassága a 30 mm magas párkányfogadó profil nélkül van megadva, mely minden ablak részét képezi, és egyszerűen lecsavarozható.'
	},
	'WindowStep - info 1': {
		[CS_CZ]: 'Klika je součástí každého okna a dveří.',
		[SK_SK]: 'Klika je súčasťou každého okna a dverí.',
		[DE_DE]: 'Fenster und Türen werden inklusive Griffe geliefert.',
		[HU_HU]: 'A kilincs minden ajtó és ablak részét képezi'
	},
	'MaterialStep - material detail - positive price - {0}': {
		[CS_CZ]: '+{0}',
		[SK_SK]: '+{0}',
		[DE_DE]: '+{0}',
		[HU_HU]: '+{0}'
	},
	'MaterialStep - material detail - negative price - {0}': {
		[CS_CZ]: '−{0}',
		[SK_SK]: '−{0}',
		[DE_DE]: '−{0}',
		[HU_HU]: '-{0}'
	},
	'MaterialStep - heading 1': {
		[CS_CZ]: 'Materiál',
		[SK_SK]: 'Materiál',
		[DE_DE]: 'Material',
		[HU_HU]: 'A keret anyaga'
	},
	'MaterialStep - button 1': {
		[CS_CZ]: 'Potvrdit výběr a pokračovat',
		[SK_SK]: 'Potvrdiť výber a pokračovať',
		[DE_DE]: 'Auswahl bestätigen und weiterleiten',
		[HU_HU]: 'Megerősítés és folytatás'
	},
	'MaterialStep - warning': {
		[CS_CZ]: '',
		[SK_SK]: '',
		[DE_DE]: '',
		[HU_HU]: ''
	},
	'ProfileStep - profile detail - positive price - {0}': {
		[CS_CZ]: '+{0}',
		[SK_SK]: '+{0}',
		[DE_DE]: '+{0}',
		[HU_HU]: '+{0}'
	},
	'ProfileStep - profile detail - negative price - {0}': {
		[CS_CZ]: '−{0}',
		[SK_SK]: '−{0}',
		[DE_DE]: '−{0}',
		[HU_HU]: '-{0}'
	},
	'ProfileStep - heading 1': {
		[CS_CZ]: 'Profil',
		[SK_SK]: 'Profil',
		[DE_DE]: 'Profil',
		[HU_HU]: 'Profilrendszer'
	},
	'ProfileStep - button 1': {
		[CS_CZ]: 'Potvrdit výběr a pokračovat',
		[SK_SK]: 'Potvrdiť výber a pokračovať',
		[DE_DE]: 'Auswahl bestätigen und weiterleiten',
		[HU_HU]: 'Megerősítés és folytatás'
	},
	'ProfileStep - warning - material 1': {
		[CS_CZ]: 'Změna profilu automaticky nastaví bílou barvu a základní sklo.',
		[SK_SK]: 'Zmena profilu automaticky nastaví bielu farbu a základné sklo.',
		[DE_DE]: 'Mit jeder Profiländerung werden die weiße Farbe und Grundglas eingestellt.',
		[HU_HU]: 'A profilrendszer megváltoztatása után automatikusan beállítódik a fehér szín és az alap üvegezés.'
	},
	'ProfileStep - warning - material 2': {
		[CS_CZ]: 'Změna profilu automaticky nastaví barvu světlé borovice a základní sklo.',
		[SK_SK]: 'Zmena profilu automaticky nastaví farbu svetlej borovice a základné sklo.',
		[DE_DE]: 'Mit jeder Profiländerung werden die helle Kiefernfarbe und Grundglas eingestellt.',
		[HU_HU]: 'A profilrendszer megváltoztatása után automatikusan beállítódik a világos fenyő szín és az alap üvegezés.'
	},
	'GlassStep - glass detail - positive price - {0}': {
		[CS_CZ]: '+{0}',
		[SK_SK]: '+{0}',
		[DE_DE]: '+{0}',
		[HU_HU]: '+{0}'
	},
	'GlassStep - glass detail - negative price - {0}': {
		[CS_CZ]: '−{0}',
		[SK_SK]: '−{0}',
		[DE_DE]: '−{0}',
		[HU_HU]: '-{0}'
	},
	'GlassStep - heading 1': {
		[CS_CZ]: 'Sklo',
		[SK_SK]: 'Sklo',
		[DE_DE]: 'Glas',
		[HU_HU]: 'Üveg'
	},
	'GlassStep - button 1': {
		[CS_CZ]: 'Potvrdit výběr a pokračovat',
		[SK_SK]: 'Potvrdiť výber a pokračovať',
		[DE_DE]: 'Auswahl bestätigen und weiterleiten',
		[HU_HU]: 'Megerősítés és folytatás'
	},
	'ColorStep - color detail - positive price - {0}': {
		[CS_CZ]: '+{0}',
		[SK_SK]: '+{0}',
		[DE_DE]: '+{0}',
		[HU_HU]: '+{0}'
	},
	'ColorStep - color detail - negative price - {0}': {
		[CS_CZ]: '−{0}',
		[SK_SK]: '−{0}',
		[DE_DE]: '−{0}',
		[HU_HU]: '-{0}'
	},
	'ColorStep - color swatch': {
		[CS_CZ]: 'Vzorník barev a dekorů (velké fotografie)',
		[SK_SK]: 'Vzorky farieb a dekorov (veľké fotografie)',
		[DE_DE]: 'Farb- und Dekormuster (große Fotos)',
		[HU_HU]: 'Szín- és dekorációs minták (nagyméretű fotók)'
	},
	'ColorStep - heading 1': {
		[CS_CZ]: 'Barva a dekor',
		[SK_SK]: 'Farba a dekor',
		[DE_DE]: 'Farbe und Dekor',
		[HU_HU]: 'Szín és dekor'
	},
	'ColorStep - sides 1': {
		[CS_CZ]: 'Vnější barva',
		[SK_SK]: 'Vonkajšia farba',
		[DE_DE]: 'Außenfarbe',
		[HU_HU]: 'Külső oldali szín '
	},
	'ColorStep - sides 2': {
		[CS_CZ]: 'Oboustranná barva',
		[SK_SK]: 'Obojstranná farba',
		[DE_DE]: 'Beidseitige Farbe',
		[HU_HU]: 'Kétoldali szín'
	},
	'ColorStep - warning': {
		[CS_CZ]: 'Vnější barva je dle vašeho výběru, vnitřní barva je vždy bílá.',
		[SK_SK]: 'Vonkajšia farba je podľa vášho výberu, vnútorná farba je vždy biela.',
		[DE_DE]: 'Außenfarbe nach Ihrer Auswahl, Innenfarbe ist immer weiß',
		[HU_HU]: 'A külső oldali szín választható, a belső oldali szín fehér.'
	},
	'ColorStep - color category 1': {
		[CS_CZ]: 'Základní',
		[SK_SK]: 'Základná',
		[DE_DE]: 'Grund',
		[HU_HU]: 'Alap'
	},
	'ColorStep - color category - {0}': {
		[CS_CZ]: 'Skupina {0}',
		[SK_SK]: 'Skupina {0}',
		[DE_DE]: 'Gruppe {0}',
		[HU_HU]: 'Csoport {0}'
	},
	'ColorStep - button 1': {
		[CS_CZ]: 'Potvrdit výběr a pokračovat',
		[SK_SK]: 'Potvrdiť výber a pokračovať',
		[DE_DE]: 'Auswahl bestätigen und weiterleiten',
		[HU_HU]: 'Megerősítés és folytatás'
	},
	'AccessoriesStep - accessory detail - price - {0}': {
		[CS_CZ]: '{0}',
		[SK_SK]: '{0}',
		[DE_DE]: '{0}',
		[HU_HU]: '{0}'
	},
	'AccessoriesStep - warning': {
		[CS_CZ]: 'Pokud chcete více než jeden kus doplňků, můžete jejich počet upravit po ukončení kalkulace a přesměrování do košíku.',
		[SK_SK]: 'Ak chcete viac ako jeden kus doplnkov, môžete ich počet upraviť po ukončení kalkulácie a presmerovanie do košíka.',
		[DE_DE]: 'Wenn Sie mehr als ein Zubehör Artikel möchten, können Sie die Anzahl anpassen, nachdem die Konfiguration agefertigt ist und Sie auf den Warenkorb  geleitet.',
		[HU_HU]: 'Amennyiben több, mint 1 darab kiegészítőre van szüksége, akkor a mennyiséget a kalkuláció végén, a kosárba való átirányításkor tudja megváltoztatni.'
	},
	'AccessoriesStep - info 1': {
		[CS_CZ]: 'Okna a dveře dodáváme kompletní včetně klik a krytek.',
		[SK_SK]: 'Okná a dvere dodávame kompletný vrátane klik a krytiek.',
		[DE_DE]: 'Fenster und Türe werden komplett mit Griffen und Deckung geliefert.',
		[HU_HU]: 'Az ablakok és ajtók részét képezik a kilincsek és a vízrés takarók.'
	},
	'AccessoriesStep - info 2': {
		[CS_CZ]: 'Další doplňky jako montážní sady, kotvy, šrouby, podložky, montážní pěnu nebo další příslušenství k oknům a dveřím, jako kliky, sítě proti hmyzu, čistící sady apod. si můžete pohodlně doobjednat po přenesní Vaší objednávky do košíku našeho eshopu.<br />Veškeré námi nabízené doplňky najdete na našem eshopu <a target="_blank" rel="noopener noreferrer" href="https://www.okna-hned.cz/26-prislusenstvi-k-oknum">www.okna-hned.cz</a> v sekci  "Příslušenství".',
		[SK_SK]: 'Ďalšie doplnky ako montážne sady, kotvy, skrutky, podložky, montážne peny alebo ďalšie príslušenstvo k oknám a dverám, ako kľučky, siete proti hmyzu, čistiace sady a pod. si môžete pohodlne doobjednať po prenesenie Vašej objednávky do košíka nášho e-shopu.<br />Všetky nami ponúkané doplnky nájdete v našom e-shope <a target="_blank" rel="noopener noreferrer" href="https://www.https://www.okna-hned.sk/26-prislusenstvo-k-oknam">www.okna-hned.sk</a> v sekcii "Príslušenstvo".',
		[DE_DE]: 'Weiteres Zubehör wie Montagesätze, Fensterrahmenlaschen, Fensterrahmenschrauben, Unterlegscheiben, Montageschaum oder weiteres Zubehör für Fenster und Türen wie Klinken, Insektennetze, Reinigungssätze usw. können Sie bequem nach Ihrer Bestellung im Warenkorb unseres E-shops bestellen.<br />Sämtliches Zubehör finden Sie in unserem E-shop unter <a target="_blank" rel="noopener noreferrer" href="https://www.fenster-jetzt.de/26-fenstermontage-zubehoer">www.fenster-jetzt.de</a> im Bereich "Zubehör".',
		[HU_HU]: 'További kiegészítők, mint a beszerelési szettek, tokrögzítő fülek, turbócsavarok, alátétek, szerelő habok valamint egyéb műanyag ablak és ajtó tartozékok, plédául  kilincsek, szúnyoghálók, tisztító szettek stb egyszerűen hozzáadhatók a megrendeléséhez a kosárban.<br />Az összes általunk kínált kiegészítőt megtalálja webshopunkban <a target="_blank" rel="noopener noreferrer" href="https://www.ablakok-most.hu/26-ablak-tartozekok">www.ablakok-most.hu</a> a "Tartozékok" menüpontban.'
	},
	
	'AccessoriesStep - heading 1': {
		[CS_CZ]: 'Výběr doplňků',
		[SK_SK]: 'Výber doplnkov',
		[DE_DE]: 'Zubehör Auswahl',
		[HU_HU]: 'A kiegészítők kiválasztása'
	},
	'AccessoriesStep - heading 2': {
		[CS_CZ]: 'Výběr žaluzií',
		[SK_SK]: 'Výber žalúzií',
		[DE_DE]: 'Jalousie Auswahl',
		[HU_HU]: 'Reluxa kiválasztása'
	},
	'AccessoriesStep - heading 3': {
		[CS_CZ]: 'Výběr vnitřního parapetu',
		[SK_SK]: 'Výber vnútorného parapetu',
		[DE_DE]: 'Innenfensterbank Auswahl',
		[HU_HU]: 'Belső ablakpárkány kiválasztása'
	},
	'AccessoriesStep - heading 4': {
		[CS_CZ]: 'Výběr vnějšího parapetu',
		[SK_SK]: 'Výber vonkajšieho parapetu',
		[DE_DE]: 'Außenfensterbank Auswahl',
		[HU_HU]: 'Külső ablakpárkány kiválasztása'
	},
	'AccessoriesStep - heading 5': {
		[CS_CZ]: 'Výběr dalších doplňků',
		[SK_SK]: 'Výber ďalších doplnkov',
		[DE_DE]: 'Weiteres Zubehör',
		[HU_HU]: 'További kiegészítők kiválasztása'
	},
	'AccessoriesStep - heading 6': {
		[CS_CZ]: 'Žaluzie',
		[SK_SK]: 'Žalúzie',
		[DE_DE]: 'Jalousie',
		[HU_HU]: 'Reluxa'
	},
	'AccessoriesStep - heading 7': {
		[CS_CZ]: 'Vnitřní parapet',
		[SK_SK]: 'Vnútorný parapet',
		[DE_DE]: 'Innenfensterbank',
		[HU_HU]: 'Belső ablakpárkány'
	},
	'AccessoriesStep - heading 8': {
		[CS_CZ]: 'Vnější parapet',
		[SK_SK]: 'Vonkajší parapet',
		[DE_DE]: 'Außenfensterbank',
		[HU_HU]: 'Külső ablakpárkány'
	},
	'AccessoriesStep - heading 9': {
		[CS_CZ]: 'Další doplněk',
		[SK_SK]: 'Ďalší doplnok',
		[DE_DE]: 'Weiteres Zubehör',
		[HU_HU]: 'További kiegészítő'
	},
	'AccessoriesStep - button 1': {
		[CS_CZ]: 'Potvrdit výběr a pokračovat',
		[SK_SK]: 'Potvrdiť výber a pokračovať',
		[DE_DE]: 'Auswahl bestätigen und weiterleiten',
		[HU_HU]: 'Megerősítés és folytatás'
	},
	'AccessoriesStep - button 2': {
		[CS_CZ]: 'Potvrdit výběr a pokračovat',
		[SK_SK]: 'Potvrdiť výber a pokračovať',
		[DE_DE]: 'Auswahl bestätigen und weiterleiten',
		[HU_HU]: 'Megerősítés és folytatás'
	},
	'AccessoriesStep - button 3': {
		[CS_CZ]: 'Potvrdit výběr a pokračovat',
		[SK_SK]: 'Potvrdiť výber a pokračovať',
		[DE_DE]: 'Auswahl bestätigen und weiterleiten',
		[HU_HU]: 'Megerősítés és folytatás'
	},
	'AccessoriesStep - button 4': {
		[CS_CZ]: 'Potvrdit výběr a pokračovat',
		[SK_SK]: 'Potvrdiť výber a pokračovať',
		[DE_DE]: 'Auswahl bestätigen und weiterleiten',
		[HU_HU]: 'Megerősítés és folytatás'
	},
	'AccessoriesStep - button 5': {
		[CS_CZ]: 'Potvrdit výběr a pokračovat',
		[SK_SK]: 'Potvrdiť výber a pokračovať',
		[DE_DE]: 'Auswahl bestätigen und weiterleiten',
		[HU_HU]: 'Megerősítés és folytatás'
	},
	'AccessoriesStep - category heading 1': {
		[CS_CZ]: 'Žaluzie',
		[SK_SK]: 'Žalúzie',
		[DE_DE]: 'Jalousie',
		[HU_HU]: 'Reluxa'
	},
	'AccessoriesStep - category heading 2': {
		[CS_CZ]: 'Parapety',
		[SK_SK]: 'Parapety',
		[DE_DE]: 'Fensterbank',
		[HU_HU]: 'Ablakpárkány'
	},
	'AccessoriesStep - category heading 3': {
		[CS_CZ]: 'Další doplňky',
		[SK_SK]: 'Ďalšie doplnky',
		[DE_DE]: 'Weiteres Zubehör',
		[HU_HU]: 'További kiegészítők'
	},
	'AccessoriesStep - accessory - price - {0}': {
		[CS_CZ]: '{0}',
		[SK_SK]: '{0}',
		[DE_DE]: '{0}',
		[HU_HU]: '{0}'
	},
	'AccessoriesStep - category button 1': {
		[CS_CZ]: 'Vybrat žaluzie',
		[SK_SK]: 'Vybrať žalúzie',
		[DE_DE]: 'Jalousie auswählen',
		[HU_HU]: 'A reluxa kiválasztása'
	},
	'AccessoriesStep - category button 2': {
		[CS_CZ]: 'Odebrat žaluzie',
		[SK_SK]: 'Odobrať žalúzie',
		[DE_DE]: 'Jalousie entfernen',
		[HU_HU]: 'A reluxa törlése'
	},
	'AccessoriesStep - category button 3': {
		[CS_CZ]: 'Vybrat vnitřní parapet',
		[SK_SK]: 'Vybrať vnútorný parapet',
		[DE_DE]: 'Innenfensterbank auswählen',
		[HU_HU]: 'Belső ablakpárkány kiválasztása'
	},
	'AccessoriesStep - category button 4': {
		[CS_CZ]: 'Vybrat vnější parapet',
		[SK_SK]: 'Vybrať vonkajši parapet',
		[DE_DE]: 'Außenfensterbank auswählen ',
		[HU_HU]: 'Külső ablakpárkány kiválasztása'
	},
	'AccessoriesStep - category button 5': {
		[CS_CZ]: 'Odebrat vnitřní parapet',
		[SK_SK]: 'Odobrať vnútorný parapet',
		[DE_DE]: 'Innenfensterbank entfernen',
		[HU_HU]: 'A belső ablakpárkány törlése'
	},
	'AccessoriesStep - category button 6': {
		[CS_CZ]: 'Odebrat vnější parapet',
		[SK_SK]: 'Odobrať vonkajši parapet',
		[DE_DE]: 'Außenfensterbank entfernen',
		[HU_HU]: 'A külső ablakpárkány törlése'
	},
	'AccessoriesStep - category button 7': {
		[CS_CZ]: 'Vybrat další doplňky',
		[SK_SK]: 'Vybrať ďalšie doplnky',
		[DE_DE]: 'Weiteres Zubehör auswählen',
		[HU_HU]: 'További kiegészítők választása'
	},
	'AccessoriesStep - select dimensions': {
		[CS_CZ]: 'Zadejte rozměry',
		[SK_SK]: 'Zadajte rozmery',
		[DE_DE]: 'Größe eingeben',
		[HU_HU]: 'Adja meng a méreteket'
	},
	'AccessoriesStep - dimension - width': {
		[CS_CZ]: 'Délka',
		[SK_SK]: 'Dĺžka',
		[DE_DE]: 'Länge',
		[HU_HU]: 'Hosszúság'
	},
	'AccessoriesStep - dimension - depth': {
		[CS_CZ]: 'Hloubka',
		[SK_SK]: 'Hĺbka',
		[DE_DE]: 'Tiefe',
		[HU_HU]: 'Mélység'
	},
	'AccessoriesStep - select color': {
		[CS_CZ]: 'Zvolte barvu',
		[SK_SK]: 'Zvoľte farbu',
		[DE_DE]: 'Wählen Sie Farbe aus',
		[HU_HU]: 'A szín kiválasztása'
	},
	'OrderStep - order item link - heading - {0}': {
		[CS_CZ]: 'Okno #{0}',
		[SK_SK]: 'Okno #{0}',
		[DE_DE]: 'Fenster #{0}',
		[HU_HU]: 'Ablak #{0}'
	},
	'OrderStep - order item link - button 1': {
		[CS_CZ]: 'Přidat',
		[SK_SK]: 'Pridať',
		[DE_DE]: 'Hinzufügen',
		[HU_HU]: 'Hozzáadás'
	},
	'OrderStep - order item link - button 2': {
		[CS_CZ]: 'Odebrat',
		[SK_SK]: 'Odobrať',
		[DE_DE]: 'Entfernen',
		[HU_HU]: 'Eltávolítás'
	},
	'OrderStep - order item link - button 3': {
		[CS_CZ]: 'Smazat',
		[SK_SK]: 'Zmazať',
		[DE_DE]: 'Löschen',
		[HU_HU]: 'Törlés'
	},
	'OrderStep - order item link - button 4': {
		[CS_CZ]: 'Přejít na toto okno',
		[SK_SK]: 'Prejsť na toto okno',
		[DE_DE]: 'Zu diesem Fenster übergehen',
		[HU_HU]: 'Ablak megnyitása'
	},
	'OrderStep - order item detail - property label 1': {
		[CS_CZ]: 'Cena za kus bez DPH',
		[SK_SK]: 'Cena za kus bez DPH',
		[DE_DE]: 'Preis pro Stück ohne MwSt.',
		[HU_HU]: 'Nettó darabár (ÁFA nélkül)'
	},
	'OrderStep - order item detail - property label 2': {
		[CS_CZ]: 'Typ okna',
		[SK_SK]: 'Typ okna',
		[DE_DE]: 'Fenstertyp',
		[HU_HU]: 'Az ablak típusa'
	},
	'OrderStep - order item detail - property label material': {
		[CS_CZ]: 'Materiál',
		[SK_SK]: 'Materiál',
		[DE_DE]: 'Material',
		[HU_HU]: 'A keret anyaga'
	},
	'OrderStep - order item detail - property label 3': {
		[CS_CZ]: 'Profil',
		[SK_SK]: 'Profil',
		[DE_DE]: 'Profil',
		[HU_HU]: 'Profil'
	},
	'OrderStep - order item detail - property label 4': {
		[CS_CZ]: 'Sklo',
		[SK_SK]: 'Sklo',
		[DE_DE]: 'Glas',
		[HU_HU]: 'Üvegezés'
	},
	'OrderStep - order item detail - property label 5': {
		[CS_CZ]: 'Barva a dekor',
		[SK_SK]: 'Farba a dekor',
		[DE_DE]: 'Farbe und Dekor',
		[HU_HU]: 'Szín és dekor'
	},
	'OrderStep - order item detail - property label 6': {
		[CS_CZ]: 'Doplňky',
		[SK_SK]: 'Doplnky',
		[DE_DE]: 'Zubehör',
		[HU_HU]: 'Kiegészítők'
	},
	'OrderStep - order item detail - property label 7': {
		[CS_CZ]: 'Počet',
		[SK_SK]: 'Počet',
		[DE_DE]: 'Anzahl',
		[HU_HU]: 'Darabszám'
	},
	'OrderStep - order item detail - property label 8': {
		[CS_CZ]: 'Cena celkem bez DPH',
		[SK_SK]: 'Cena celkom bez DPH',
		[DE_DE]: 'Gesamtpreis ohne MwSt. ',
		[HU_HU]: 'Nettó ár (ÁFA nélkül)'
	},
	'OrderStep - order item detail - property label 9': {
		[CS_CZ]: 'Žaluzie',
		[SK_SK]: 'Žalúzie',
		[DE_DE]: 'Jalousie',
		[HU_HU]: 'Reluxa'
	},
	'OrderStep - order item detail - property label 10': {
		[CS_CZ]: 'Vnitřní parapet',
		[SK_SK]: 'Vnútorný parapet',
		[DE_DE]: 'Innenfensterbank',
		[HU_HU]: 'Belső ablakpárkány'
	},
	'OrderStep - order item detail - property label 11': {
		[CS_CZ]: 'Vnější parapet',
		[SK_SK]: 'Vonkajši parapet',
		[DE_DE]: 'Außenfensterbank',
		[HU_HU]: 'Külső ablakpárkány'
	},
	'OrderStep - order item detail - property label 12': {
		[CS_CZ]: 'Další doplňky',
		[SK_SK]: 'Ďalšie doplnky',
		[DE_DE]: 'Weiteres Zubehör',
		[HU_HU]: 'További kiegészítők'
	},
	'OrderStep - order item detail - property label 13': {
		[CS_CZ]: 'Cena celkem včetně DPH',
		[SK_SK]: 'Cena celkom vrátane DPH',
		[DE_DE]: 'Gesamtpreis MwSt. Inklusive',
		[HU_HU]: 'Bruttó ár (27%-os ÁFÁ-val)'
	},
	'OrderStep - order item detail - property value - {0}': {
		[CS_CZ]: '{0}',
		[SK_SK]: '{0}',
		[DE_DE]: '{0}',
		[HU_HU]: '{0}'
	},
	'OrderStep - heading 1': {
		[CS_CZ]: 'Nakalkulovaná okna',
		[SK_SK]: 'Nakalkulovaná okná',
		[DE_DE]: 'Berechnete Fenster',
		[HU_HU]: 'A megszerkesztett ablakok'
	},
	'OrderStep - button 1': {
		[CS_CZ]: 'Vrátit se ke kalkulaci',
		[SK_SK]: 'Vrátiť sa na kalkuláciu',
		[DE_DE]: 'Zurück zur Konfiguration',
		[HU_HU]: 'Vissza a szerkesztéshez'
	},
	'OrderStep - button 1 - alt': {
		[CS_CZ]: 'Vrátit se k výběru',
		[SK_SK]: 'Vrátiť sa k výberu',
		[DE_DE]: 'Zurück zur Auswahl',
		[HU_HU]: 'Vissza a kiválasztáshoz'
	},
	'OrderStep - button 2': {
		[CS_CZ]: 'Přejít k objednávce',
		[SK_SK]: 'Prejsť k objednávke',
		[DE_DE]: 'Zur Bestellung',
		[HU_HU]: 'Átirányítás a kosárba '
	},
	'OrderStep - button 2 - alt': {
		[CS_CZ]: 'Vložit do košíku',
		[SK_SK]: 'Vložiť do košíka',
		[DE_DE]: 'In den Warenkorb legen',
		[HU_HU]: 'Kosárba'
	},
	'OrderStep - phone': {
		[CS_CZ]: 'Vaše telefonní číslo',
		[SK_SK]: 'Vaše telefonní číslo',
		[DE_DE]: 'Ihre Telefonnummer',
		[HU_HU]: 'Az Ön telefonszáma'
	}
};

export default i18nStrings;
