// import Inferno from 'inferno';
// import Component from 'inferno-component';
import React from 'react';

import styles from './GlassStep.css';
import openStepActionStream from '../streams/openStepActionStream';
import dataStream from '../streams/dataStream';
import orderStream from '../streams/orderStream';
import {Glass} from '../types/data';
import selectGlassActionStream from '../streams/selectGlassActionStream';
import Button from './Button';
import ButtonGroup from './ButtonGroup';
import link from '../utils/link';
import getOrderItemPrice from '../utils/getOrderItemPrice';
import getConvertedPrice from '../utils/getConvertedPrice';
import Stream from '../libs/Stream';
import I18n from '../libs/I18n';
import languageStream from '../streams/languageStream';
import config from '../config';

const {VAT_MODIFIER} = config;

let i18n = new I18n();

export interface GlassDetailProps {
	glass: Glass;
	isSelected: boolean;
	handleClick: (glass: Glass) => void;
}

export class GlassDetail extends React.Component<GlassDetailProps, {}> {
	render() {
		let order = orderStream.value;
		let orderItem;
		
		if (order.currentItem !== null) {
			orderItem = order.items[order.currentItem];
		}
		
		if (!this.props) {
			return;
		}
		
		let {windowPrice: currentWindowPrice} = getOrderItemPrice(orderItem);
		let {windowPrice: nextWindowPrice} = getOrderItemPrice({
			...orderItem,
			glass: {
				id: this.props.glass.id,
				name: this.props.glass.name,
				price: this.props.glass.price
			}
		});
		let delta = nextWindowPrice && currentWindowPrice ? getConvertedPrice((nextWindowPrice - currentWindowPrice) * VAT_MODIFIER[languageStream.value.current]) : 0;
		// console.log("delta", [currentWindowPrice, nextWindowPrice, delta]);
		
		return (
			<div className={styles.glassDetail + (this.props.isSelected ? ` ${styles.isSelected}` : '')} onClick={this.handleClick}>
				<h4 className={styles.glassDetailHeading}>{this.props.glass.name[languageStream.value.current]}</h4>
				<figure className={styles.glassDetailPhoto}>
					<img src={link('assets', this.props.glass.image)} alt={this.props.glass.name[languageStream.value.current]} />
				</figure>
				<p className={styles.glassDetailPriceComparison}>
					{delta >= 0 ?
						i18n.translate `GlassStep - glass detail - positive price - ${delta}:c` :
						i18n.translate `GlassStep - glass detail - negative price - ${delta * -1}:c`}
				</p>
			</div>
		);
	}
	
	handleClick = (event) => {
		if (this.props) {
			this.props.handleClick(this.props.glass);
		}
	};
}

export interface GlassStepProps {
	isOpen: boolean;
}

export default class GlassStep extends React.Component<GlassStepProps, {}> {
	onLanguageStream: Stream<{}>;
	
	componentDidMount() {
		this.onLanguageStream = languageStream.on(() => {
			// console.log('GlassStep 1');
			// requestAnimationFrame(() => {
			//	 console.log('GlassStep 2');
			this.forceUpdate();
			// });
		});
	}
	
	componentWillUnmount() {
		this.onLanguageStream.end.push(true);
	}
	
	render() {
		let {glasses} = dataStream.value;
		let orderItem = orderStream.value.currentItem === null ? undefined : orderStream.value.items[orderStream.value.currentItem];
		if (!orderItem) {
			return null;
		}
		
		let filteredGlasses = glasses.filter(
			(glass) => orderItem && orderItem.material && glass.materialIds.includes(orderItem.material.id) && orderItem.profile && glass.profileIds.includes(orderItem.profile.id)
		);
		// console.log('filteredGlasses', filteredGlasses);
		
		return (
			<section className={styles.root + (this.props && this.props.isOpen ? ` ${styles.isOpen}` : '')}>
				<div className={styles.wrapper}>
					<h1 className={styles.heading}>{i18n.translate `GlassStep - heading 1`}</h1>
					<span className={styles.closeButton}>
						<Button type="invisible" size="large" icon="cross" handleClick={this.handleCloseClick}/>
					</span>
					
					<div className={styles.glass}>
						<ul className={styles.glassesList}>
							{filteredGlasses.map((glass, index) => <li key={index}>
								<GlassDetail
									glass={glass}
									isSelected={orderItem && orderItem.glass && glass.id === orderItem.glass.id}
									handleClick={this.handleSelectGlass}
								/>
							</li>
							)}
						</ul>
						
						<ButtonGroup align="center">
							<Button
								type="flat"
								size="large"
								label={i18n.translate `GlassStep - button 1`}
								isDisabled={!(orderItem && orderItem.glass)}
								handleClick={this.handleCloseClick}
							/>
						</ButtonGroup>
					</div>
				</div>
			</section>
		);
	}
	
	handleCloseClick = () => {
		openStepActionStream.push(null);
	};
	
	handleSelectGlass = (glass) => {
		selectGlassActionStream.push({
			id: glass.id,
			name: glass.name,
			price: glass.price
		});
	};
}
