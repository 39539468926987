import styles from './styles';
import i18nStrings from './i18nStrings';
import constants from '../internals/constants';

const {CS_CZ} = constants;
const {SK_SK} = constants;
const {DE_DE} = constants;
const {DE_AT} = constants;
const {HU_HU} = constants;

export interface Config {
	styles: any;
	i18nStrings: any;
	urlAppRoot: string;
	ONE_PLN_IN_CZK: number;
	ONE_CZK_IN_EUR: number;
	ONE_CZK_IN_HUF: number;
	VAT_MODIFIER: number;
	MARGIN: number;
	MARGIN_WOOD: number;
	WEIGHT_OF_M2_IN_KG: number;
	ALT_NAME_HEIGHT: number;
	COEFFICIENT: number;
	COEFFICIENT_WOOD: number;
	WITRAZ: number;
}

let config: Config = {
	styles,
	i18nStrings,
	urlAppRoot: process.env.NODE_ENV === 'production' ? '/' : '/', // should be e.g. `/` or `/root`
	ONE_PLN_IN_CZK: 5.9,
	ONE_CZK_IN_EUR: 0.0423,
	ONE_CZK_IN_HUF: 17.044491,
	VAT_MODIFIER: {
		[CS_CZ]: 1.21,
		[SK_SK]: 1.2,
		[DE_DE]: 1.19,
		[DE_AT]: 1.20,
		[HU_HU]: 1.27
	},
	MARGIN: 1.3,
	MARGIN_WOOD: 1.35,
	SUNBLIND_MARGIN: 1,
	WEIGHT_OF_M2_IN_KG: 35,
	ALT_NAME_HEIGHT: 1900,
	COEFFICIENT: 2.065,
	COEFFICIENT_WOOD: 1,
	WITRAZ: 0.59
};

export default config;
